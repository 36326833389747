<template>
  <router-link
    :is="query && Number.isInteger(query.offset) ? 'router-link' : 'span'"
    :to="{
      name: 'details-signalement-filtre',
      params: { slug },
      query,
    }"
  >
    {{ properties.title || featureId }}
  </router-link>
</template>

<script>

import { mapState } from 'vuex';
import axios from '@/axios-client.js';

import projectAPI from '@/services/project-api';

export default {

  name: 'FeatureFetchOffsetRoute',

  props: {
    featureId: {
      type: String,
      default: '',
    },
    properties: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      position: null,
      slug: this.$route.params.slug || this.properties.project_slug,
      ordering: null,
      filter: null,
    };
  },
  
  computed: {
    ...mapState('projects', [
      'project'
    ]),
    query() {
      if (this.ordering) {
        const searchParams = { ordering: this.ordering };
        if (this.filter === 'feature_type_slug') { // when feature_type is the default filter of the project, 
          searchParams['feature_type_slug'] = this.properties.feature_type.slug; // get its slug for the current feature
        }
        if (Number.isInteger(this.position)) {
          searchParams['offset'] = this.position; // get its slug for the current feature
        }
        return searchParams;
      }
      return null;
    },
  },

  watch: {
    featureId() {
      this.initData();
    }
  },

  created() {
    this.initData();
  },

  methods: {
    async initData() {
      if (this.project) {
        this.setProjectParams(this.project);
      } else {
        await this.getProjectFilterAndSort();
      }
      this.getFeaturePosition(this.featureId)
        .then((position) => {
          if (Number.isInteger(position)) {
            this.position = position;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setProjectParams(project) {
      this.ordering = project.feature_browsing_default_sort;
      if (project.feature_browsing_default_filter === 'feature_type_slug') { // when feature_type is the default filter of the project, 
        this.filter = this.properties.feature_type.slug;
      }
    },

    async getFeaturePosition(featureId) {
      const searchParams = new URLSearchParams(this.query);
      const response = await axios.get(`${this.$store.state.configuration.VUE_APP_DJANGO_API_BASE}projects/${this.slug}/feature/${featureId}/position-in-list/?${searchParams.toString()}`);
      if (response && response.status === 200) {
        return response.data;
      }
      return null;
    },

    async getProjectFilterAndSort() {
      const project = await projectAPI.getProject(this.$store.state.configuration.VUE_APP_DJANGO_API_BASE, this.slug);
      if (project) this.setProjectParams(project);
    }
  }
};
</script>
