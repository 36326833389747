export function fileConvertSize(aSize){
  aSize = Math.abs(parseInt(aSize, 10));
  const def = [[1, 'octets', 0], [1024, 'ko', 0], [1024*1024, 'Mo', 1], [1024*1024*1024, 'Go', 2], [1024*1024*1024*1024, 'To', 2]];
  for (let i=0; i<def.length; i++) {
    if (aSize<def[i][0]) {
      return (aSize/def[i-1][0]).toFixed(def[i-1][2]) + ' ' + def[i-1][1];
    }
  }
}

export function fileConvertSizeToMo(aSize){
  aSize = Math.abs(parseInt(aSize, 10));
  const def = [1024*1024, 'Mo', 1];
  return (aSize/def[0]).toFixed(def[2]);
}

/**
 * Determines the likely field delimiter in a CSV string by analyzing the first few lines.
 * The function counts the occurrences of common delimiters such as commas, semicolons, and tabs.
 * The most frequently and consistently occurring delimiter across the sampled lines is chosen as the likely delimiter.
 *
 * @param {string} text - The CSV string to analyze for determining the delimiter.
 * @returns {string|false} The most likely delimiter character if one can be determined, or false if none is found.
 */
export function determineDelimiter(text) {
  const lines = text.split('\n').slice(0, 5); // Analyze the first 5 lines
  const delimiters = [',', ';', '\t']; // List of possible delimiters
  let delimiterCounts = new Map(delimiters.map(d => [d, 0])); // Initialize a map to count delimiter occurrences

  // Count the occurrences of each delimiter in each line
  lines.forEach(line => {
    delimiters.forEach(delimiter => {
      const count = line.split(delimiter).length - 1; // Count the occurrences of the delimiter in the line
      delimiterCounts.set(delimiter, delimiterCounts.get(delimiter) + count); // Update the count in the map
    });
  });

  let mostCommonDelimiter = '';
  let maxCount = 0;

  // Determine the most common delimiter
  delimiterCounts.forEach((count, delimiter) => {
    if (count > maxCount) {
      mostCommonDelimiter = delimiter; // Set the most common delimiter found so far
      maxCount = count; // Update the maximum count found so far
    }
  });

  return mostCommonDelimiter || false; // Return the most common delimiter or false if none is found
}

/**
 * Parses a CSV string into an array of rows, where each row is an array of fields.
 * The function correctly handles multiline fields enclosed in double quotes, removes
 * carriage return characters (\r) at the end of lines, and allows for different field
 * delimiters.
 * 
 * @param {string} text - The CSV string to be parsed.
 * @param {string} delimiter - The field delimiter character (default is comma ',').
 * @returns {Array<Array<string>>} An array of rows, each row being an array of fields.
 */
export function parseCSV(text, delimiter = ',') {
  let rows = [];     // This will hold the parsed rows
  let row = [];      // Temporary array to hold the fields of the current row
  let field = '';    // Temporary string to hold the current field
  let inQuotes = false; // Boolean to track whether we are inside quotes

  for (let i = 0; i < text.length; i++) {
    const char = text[i]; // Current character

    if (char === '"' && text[i - 1] !== '\\') {
      inQuotes = !inQuotes; // Toggle the inQuotes flag if not escaped
    } else if (char === delimiter && !inQuotes) {
      // If the current character is the delimiter and we are not inside quotes,
      // add the field to the row and reset the field variable
      row.push(field.replace(/\r$/, '')); // Remove trailing carriage return
      field = '';
    } else if (char === '\n' && !inQuotes) {
      // If the current character is a newline and we are not inside quotes,
      // add the field to the row, add the row to the list of rows,
      // and reset the field and row variables
      row.push(field.replace(/\r$/, '')); // Remove trailing carriage return
      rows.push(row);
      row = [];
      field = '';
    } else {
      // If the current character is part of a field, add it to the field variable
      field += char;
    }
  }

  // After the loop, check if there's a remaining field or row to be added
  if (field) {
    row.push(field.replace(/\r$/, '')); // Remove trailing carriage return
    rows.push(row);
  }

  return rows; // Return the parsed rows
}

/**
 * Checks if the values in 'lon' and 'lat' columns are decimal numbers in the provided CSV data.
 *
 * @param {Array<string>} headers - The array of headers from the CSV file.
 * @param {Array<Array<string>>} data - The CSV data as an array of rows, each row being an array of field values.
 * @returns {boolean} True if 'lon' and 'lat' are found and their values are decimal numbers, false otherwise.
 */
export function checkLonLatValues(headers, data) {
  const lonIndex = headers.indexOf('lon');
  const latIndex = headers.indexOf('lat');

  // Check if both 'lon' and 'lat' headers are found
  if (lonIndex === -1 || latIndex === -1) {
    return false;
  }

  // Function to check if a string is a decimal number
  const isDecimal = (str) => !isNaN(str) && str.includes('.');

  for (const row of data) {
    // Check if 'lon' and 'lat' values are decimal numbers
    if (!isDecimal(row[lonIndex]) || !isDecimal(row[latIndex])) {
      return false;
    }
  }

  return true;
}
